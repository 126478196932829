import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const ClientsFilter = ({doSearch}) => {
  const [focusedEmployeeName, setFocusedEmployeeName] = useState(false);
  const [inputValueEmployeeName, setInputValueEmployeeName] = useState("");


  const handleLabelClickEmployeeName = () => {
    setFocusedEmployeeName(true);
  };

  const handleInputBlurEmployeeName = () => {
    if (inputValueEmployeeName === "") {
      setFocusedEmployeeName(false);
    }
  };

  const handleInputChangeEmployeeName = (e) => {
    const value = e.target.value;
    setInputValueEmployeeName(value);
    if (value !== "" && !focusedEmployeeName) {
      setFocusedEmployeeName(true);
    }
  };

  return (
    <>
      <div className="row filter-row">
        {/* <div className="col-sm-6 col-md-3">
          <div
            className={
              focusedClientId
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValueClientId}
              onFocus={handleLabelClickClientId}
              onBlur={handleInputBlurClientId}
              onChange={handleInputChangeClientId}
            />
            <label className="focus-label" onClick={handleLabelClickClientId}>
              Client ID
            </label>
          </div>
        </div> */}
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focusedEmployeeName
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValueEmployeeName}
              onFocus={handleLabelClickEmployeeName}
              onBlur={handleInputBlurEmployeeName}
              onChange={handleInputChangeEmployeeName}
            />
            <label
              className="focus-label"
              onClick={handleLabelClickEmployeeName}
            >
              Search client by name, phone or email
            </label>
          </div>
        </div>
       
        <div className="col-sm-6 col-md-3">
          <Link onClick={() => doSearch(inputValueEmployeeName)} to="#" className="btn btn-success btn-block w-100">
            {" "}
            Search{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ClientsFilter;
