import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import TicketFilter from "../../../../../components/TicketFilter";
import { useBookingHistoryMutation } from "../../../../../services/booking.service";
import { exportToExcel, pad } from "../../../../../utils";
import dayjs from "dayjs";
import ViewGenerators from "../../../../../components/modelpopup/ViewGenerators";
import ViewBookingCustomers from "../../../../../components/modelpopup/ViewBookingCustomersModal";


const BookingReports = () => {
  const [generators, setGenerators] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [showFilter, toggleFilter] = useState(null);
  const [doExport, setExport] = useState(false);
  const [showNewCustomers, setShowNewCustomers] = useState(false)
  const [showOldCustomers, setShowOldCustomers] = useState(false)
  const [getBooking, { data, isLoading: isLoading, isSuccess: isFetch, isError: isFetchError }] = useBookingHistoryMutation();
  const [filterData, setFilterData] = useState({
    companyName: '',
    status: '',
    generator: '',
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    type: 'history',
    page: 1,
    customerType: 'count',
  })

  useEffect(() => {
    getBooking(filterData);
  }, []);

  useEffect(() => {
    if (data && !doExport) { 
      setBookings(data.bookings);
      
      if (showOldCustomers) {
        setCustomers(data.returningCustomers);
      }

      if (showNewCustomers) {
        setCustomers(data.newCustomers);
      }
    }

    if (data && doExport) {
      exportToExcel(data, `PPS Invoices ${dayjs(filterData.startDate).format('YYYY-MM-DD')}-${dayjs(filterData.endDate).format('YYYY-MM-DD')}`);
      setExport(false)
      setFilterData({...filterData, export: false})
    }
  }, [data]);

  const onChange = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: value
    })
  }

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "booking_id",
      render: (text, record) => (
        <Link
          onClick={() => localStorage.setItem("minheight", "true")}
          to={`/booking/${record.id}`}
        >
          #PPS-{pad(record.booking_id, 4)}
        </Link>
      ),
      sorter: (a, b) => a.booking_id.length - b.booking_id.length,
    },

    {
      title: "Client",
      dataIndex: "ticketsubject",
      sorter: (a, b) => a.ticketsubject.length - b.ticketsubject.length,
      render: (text, record) => (
        <span className="table-avatar">

          <Link to="/profile">{record.customer.company_name}</Link>
        </span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "job_status",
      render: (text) => (
        
        <span
          className={
            (text === "PAID" || text === "CLOSED")
              ? "badge bg-success"
              : text === "PARTIAL"
              ? "badge bg-inverse-info"
              : text === "PENDING"
              ? "badge bg-warning"
              : text === "FREE"
              ? "badge bg-info"
              : "badge bg-danger"
          }>
          {text}
        </span>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      sorter: (a, b) => a.event_date.length - b.event_date.length,
      render: (text, r) => (
        <div>
          {dayjs(r.startDate).format('DD MMM YYYY')}
          {" "}
          {r.startDate !== r.endDate && <>
          - {dayjs(r.endDate).format('DD MM YYYY')}</>}
        </div>
      )
    },
    {
      title: "Generator",
      dataIndex: "name",
      render: (text, record) => (
        record.generators.length > 0 ?
        <div>
          {record.generators.length > 1 ?
            <Link
              className="text-info"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#generator_details"
              onClick={() => setGenerators(record?.generators)}
            >
              {record.generators[0].generator.name} ({record.generators[0].generator.size}) + {record.generators.length - 1} more
            </Link>
          :
            `${record.generators[0]?.generator?.name} (${record.generators[0]?.generator?.size}kva)`
          }
        </div>
        : 'N/A'
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      // render: () => (<div>Soul Beach, Oniru</div>)
    },
    {
      title: "Contact Details",
      dataIndex: "priority",
      render: (text, record) => (
        <div>
          {record.customer.contact_name && <div><span className="text-bold">Name:</span> {record.customer.contact_name}</div>}
          {record.customer.phone_no && <div><span className="text-bold">Phone No:</span> {record.customer.phone_no}</div>}
        </div>
      ),
    },
    {
      title: 'Extras',
      render: (t, record) => (
        <div>
          {record.extra_cable && <div><span className="text-bold">XCable: </span>{record.extra_cable}m</div>}
          {record.distribution_box && <div><span className="text-bold">DB: </span>{record.distribution_box} DBs</div>}
          {record.change_over && <div><span className="text-bold">C/O: </span>{record.change_over} units</div>}
          {record.supply_diesel && <div><span className="text-bold">Diesel:</span> {record.supply_diesel}L</div>}
        </div>
      )
    },
    {
      title: "Technician",
      dataIndex: "priority",
      // render: () => (<div></div>)
    },
    {
      title: "Status",
      dataIndex: "delivery_status",
      render: (text) => (
        <span
          className={
            text === "DELIVERED"
              ? "badge bg-inverse-success"
              : text === "CANCELLED"
              ? "badge bg-inverse-danger"
              : text === "NOT_STARTED"
              ? "badge bg-inverse-warning"
              : text === "IN_TRANSIT"
              ? "badge bg-inverse-info"
              : "badge bg-inverse-default"
          }>
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Date Recorded",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      render: (text) => (dayjs(text).format('DD MMM YYYY HH:mm'))
    },
    {
      title: "Action",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <Link
              className="dropdown-item"
              to={`/booking/${r.id}`}
            >
              <i className="fa fa-eye m-r-5" /> View Details
            </Link>
        
            {r.invoiced &&
              <Link
                className="dropdown-item"
                to={`/invoice/${r.id}`}
              >
                <i className="fa fa-file-invoice m-r-5" /> View Invoice
              </Link>
            }
          </div>
        </div>
      ),
      sorter: true,
    },
  ];


  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Booking History"
            title="Dashboard"
            subtitle="Reports"
            canRefresh={true}
            canFilter={true}
            canExport={true}
            showFilter={showFilter}
            onRefresh={() => getBooking(filterData)}
            toggleFilter={() => toggleFilter(!showFilter)}
            doExport={() => {
              setExport(true);
              getBooking({...filterData, export: true});
            }}
          />

          <div className="row">
            <div className="col-md-3 d-flex">
              <div className="stats-info w-100 mb-0">
                <h6>Total Bookings</h6>
                <h4>{data?.totalBookings || 0}</h4>
              </div>
            </div>
            <div className="col-md-3 d-flex">
              <div className="stats-info w-100 mb-0">
                <h6>Total Cancelled</h6>
                <h4>{data?.totalCancelled || 0}</h4>
              </div>
            </div>
            <Link 
              className="col-md-3 d-flex" 
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#customers_list"
              onClick={() => {
                getBooking({...filterData, customerType: 'old'});
                setShowOldCustomers(true)
              }}  
            >
              <div className="stats-info w-100 mb-0">
                <h6>Returning Customers</h6>
                <h4>{showOldCustomers ? data?.returningCustomers.length : data?.returningCustomers}</h4>
              </div>
            </Link>
            <Link 
              className="col-md-3 d-flex" 
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#customers_list"
              onClick={() => {
                getBooking({...filterData, customerType: 'new'});
                setShowNewCustomers(true)
              }}  
            >
              <div className="stats-info w-100 mb-0">
                <h6>New Customers</h6>
                <h4>{showNewCustomers ? data?.newCustomers.length : data?.newCustomers}</h4>
              </div>
            </Link>
          </div>

          <hr />
          {showFilter && 
            <TicketFilter 
              onChange={onChange} 
              filterData={filterData} 
              doSearch={() =>{

                getBooking({...filterData, customerType: 'count'});
                setShowNewCustomers(false);
                setShowOldCustomers(false);
              }}
              type="history" 
            />
          }
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={bookings?.data?.length > 0 ? bookings?.data : []}
                  loading={isLoading}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: data?.totalBookings,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      const payload = {...filterData}
                      setFilterData({...filterData, page})
                      payload.page = page;
                      payload.startDate = dayjs(filterData.startDate).format('YYYY-MM-DD')
                      payload.endDate = dayjs(filterData.endDate).format('YYYY-MM-DD')
                      getBooking(payload);
                    },
                  }}                
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewGenerators 
        generators={generators} 
        isInvoices={false}
        closeGen={() => setGenerators([])}
      />
      <ViewBookingCustomers 
        customers={customers} 
        type="booking-reports"
        close={() => {
          setCustomers([]);
        }} 
      />
    </>
  );
};

export default BookingReports;
