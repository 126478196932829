import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useLazyGetBookingQuery } from "../../services/booking.service";

const ViewBookingDetailsPopup = ({bookingId}) => {

  const [getBooking, {isFetching, data}] = useLazyGetBookingQuery();

  useEffect(() => {
    getBooking(bookingId);
  }, [bookingId]);

  return (
    <>
      <div id="view_customer_booking" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Booking Details</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Person</label>
                      <span className="form-control">{data?.contact_person}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Company Name</label>
                      <span className="form-control">{data?.customer.company_name}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Number</label>
                      <span className="form-control">{data?.contact_phone}</span>
                      
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Email</label>
                      <span className="form-control">{data?.contact_email}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Event Type</label>
                      <span className="form-control">{data?.event_type}</span>
                      
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Event Location</label>
                      <span className="form-control">{data?.event_location}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Address</label>
                      <span className="form-control">{data?.address}</span>
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                    <label className="col-form-label">Event Date(s)</label>
                    <span className="form-control">
                    {dayjs(data?.startDate).format('DD MMM YYYY')}
                    {" "}
                    {data?.startDate !== data?.endDate && <>
                    - {dayjs(data?.endDate).format('DD MMM YYYY')}</>}
                    </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                    <label className="col-form-label">Running Hours</label>
                      <span className="form-control">{data?.running_hours} hours</span>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Change Over</label>
                      <span className="form-control">{data?.change_over}</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Supply Diesel</label>
                      <span className="form-control">{data?.supply_diesel}</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Extra Cable</label>
                      <span className="form-control">{data?.extra_cable}</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Distribution Box</label>
                      <span className="form-control">{data?.distribution_box}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <h4>Generators Booked</h4>
                    <table className="table table-condensed table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="col-md-6">Description</th>
                          <th>Capacity Needed</th>
                          <th>Fee</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody className="tbodyone">
                        
                        {data?.generators.map((gen, idx) => (
                          <tr key={`gen-${idx}`}>
                            <td>{idx + 1}</td>
                            <td>{gen.description}</td>
                            <td>{gen.capacity}kva</td>
                            <td>{gen.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Add Ons</label>
                      <textarea className="form-control" defaultValue={data?.add_on} />
                    </div>
                    
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBookingDetailsPopup;
