import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useLazyGetSchedulesQuery } from "../../../../../services/booking.service";
import dayjs from "dayjs";
import ViewBookingDetailsPopup from "../../../../../components/modelpopup/ViewBookingDetailsPopup";

const Calendar = () => {
  const [weekendsVisible] = useState(true);
  const [events, setEvents] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    start: dayjs().startOf('M').format('YYYY-MM-DD'),
    end: dayjs().endOf('M').format('YYYY-MM-DD'),
  });
  const [getBookings, { data, isLoading: isLoading, isSuccess: isFetch, isError: isFetchError }] = useLazyGetSchedulesQuery();

  // useEffect(() => {
  //   getBookings(dateFilter);
  // }, []);

  useEffect(() => {
    getBookings(dateFilter);
  }, [dateFilter]);


  useEffect(() => {
    if (data) {
      setEvents(data.map((booking) => ({
        id: booking.id,
        title: booking.customer.company_name,
        start: booking.startDate,
        end: booking.endDate,
        className: "bg-info"
      })))
    }
  }, [data]);

  // const defaultEvents = [
  //   {
  //     title: "Event Name 4",
  //     start: Date.now() + 148000000,
  //     className: "bg-purple",
  //   },
  //   {
  //     title: "Test Event 1",
  //     start: Date.now(),
  //     end: Date.now(),
  //     className: "bg-success",
  //   },
  //   {
  //     title: "Test Event 2",
  //     start: Date.now() + 168000000,
  //     className: "bg-info",
  //   },
  //   {
  //     title: "Test Event 3",
  //     start: Date.now() + 338000000,
  //     className: "bg-primary",
  //   },
  // ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Events</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="admin-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Events</li>
              </ul>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    {/* Calendar */}
                    <div id="calendar" />
                    <FullCalendar
                      
                      datesSet={(arg) => {
                        setDateFilter({
                          start: dayjs(arg.view.activeStart).format('YYYY-MM-DD'),
                          end: dayjs(arg.view.activeEnd).format('YYYY-MM-DD')
                        })
                      }}
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        // right: "dayGridMonth",
                      }}
                      initialView="dayGridMonth"
                      displayEventTime={false}
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      dayMaxEventRows={4}
                      weekends={weekendsVisible}
                      events={events}
                      eventClick={(data) => {
                        document.getElementById('open-details').click();
                        setBookingId(data.event.id);
                      }}
                      // initialEvents={defaultEvents}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button 
        style={{display: 'none'}}  
        id="open-details" 
        data-bs-toggle="modal"
        data-bs-target="#view_customer_booking"
      />
      <ViewBookingDetailsPopup bookingId={bookingId} />
    </div>
  );
};

export default Calendar;
