import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const GeneratorReportTable = ({data, loading}) => {

  const columns = [
    {
      title: "Generator",
      dataIndex: "name",
      render: (text) => <Link to="#">{text}</Link>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Size",
      dataIndex: "size",
      sorter: (a, b) => a.size.length - b.size.length,
      render: (text, record) => (`${text} kva`)
    },
    {
      title: "Total Running Hours",
      dataIndex: "hours",
      sorter: (a, b) => a.hours.length - b.hours.length,
    },
    {
      title: "Total Bookings",
      dataIndex: "total",
      sorter: (a, b) => a.bookings.length - b.bookings.length,
    },
    {
      title: "Total Confirmed Bookings",
      dataIndex: "confirmed_bookings",
      render: (text) => <Link to="#">{text}</Link>,
      sorter: (a, b) => a.confirmed_bookings.length - b.confirmed_bookings.length,
    },
    // {
    //   title: "Amount",
    //   dataIndex: "amount",
    //   sorter: (a, b) => a.amount.length - b.amount.length,
    // },
  ];
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            loading={loading}
            pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100']}}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneratorReportTable;
