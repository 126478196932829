import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useLazyGetLeadsQuery, useUpdateLeadMutation } from "../../../services/customer.service";
import AddLeadModal from "../../../components/modelpopup/AddLeadModal";
import dayjs from "dayjs";
import alertify from "alertifyjs";
import LeadCommentBox from "../../../components/modelpopup/LeadCommentBox";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { useDispatch } from "react-redux";
import { LEADS } from "../../../services/constants";
import { exportToExcel } from "../../../utils";

const Leads = () => {
  const [getLeads, { data, isLoading, error }] = useLazyGetLeadsQuery()
  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState(null);
  const [doExport, setExport] = useState(false);
  const [updateStatus, { isLoading: isUpdating }] = useUpdateLeadMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    getLeads({page: 1, canExport: false})
  }, []);

  useEffect(() => {
    if (data && !doExport) setLeads(data.data);

    if (data && doExport) {
      exportToExcel(data, `PPS Leads`);
      setExport(false)
    }
  }, [data])

  const edit = item => {
    setLead(item);
    document.getElementById('add-btn').click();
  }

  const changeStatus = async (id, status, index) => {
    await updateStatus({id, status}).then(res => {
      const saveData = res.data
      if (saveData?.success ) {
        alertify.success("Saved successfully");
        const newArr = [...leads];
        const newObj = {...newArr[index]}
        newObj.status = status;
        newArr[index] = newObj;
        setLeads(newArr);
      } else {
        alertify.error(saveData?.message);
      }
    })
  }

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Lead',
        url: `${LEADS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#lead_comment_box"
              onClick={() => setLead(record)}
            >
              <i className="fa fa-comment m-r-5" /> Comments
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => edit(record)}>
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link className="dropdown-item" to={`/new-booking?leadId=${record.id}`}>
              <i className="fa fa-plus m-r-5" /> Add Booking
            </Link>
            <Link className="dropdown-item" to="#" onClick={(e) => doDelete(e, record)}>
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      render: (text, record) => (
        <span className="table-avatar">
          {text}
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      sorter: (a, b) => a.project.length - b.project.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Mobile",
      dataIndex: "phoneNo",
      sorter: (a, b) => a.phoneNo.length - b.phoneNo.length,
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a.location.length - b.location.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (t, r, index) => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            aria-expanded="false"
            data-bs-toggle="dropdown"
          >
            {r.status === 'INITIATED' && <><i className="far fa-dot-circle text-warning" /> Initiated{" "}</>}
            {r.status === 'PROPOSAL_SENT' && <><i className="far fa-dot-circle text-info" /> Proposal Sent{" "}</>}
            {r.status === 'CLOSED_WON' && <><i className="far fa-dot-circle text-success" /> Closed - Won{" "}</>}
            {r.status === 'CLOSED_LOST' && <><i className="far fa-dot-circle text-danger" /> Closed - Lost{" "}</>}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {r.status !== 'INITIATED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'INITIATED', index)}>
                <i className="far fa-dot-circle text-pending" /> Initiated
              </Link>
            }
            {r.status !== 'PROPOSAL_SENT' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'PROPOSAL_SENT', index)}>
              <i className="far fa-dot-circle text-info" /> Proposal Sent
              </Link>
            }
            {r.status !== 'CLOSED_WON' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'CLOSED_WON', index)}>
              <i className="far fa-dot-circle text-success" /> Closed - Won
              </Link>
            }
            {r.status !== 'CLOSED_LOST' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'CLOSED_LOST', index)}>
              <i className="far fa-dot-circle text-danger" /> Closed - Lost
              </Link>
            }
          </div>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      render: (text, record) => (
        dayjs(record.createdAt).format('DD MMM YYYY HH:mm')
      )
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#lead_comment_box"
              onClick={() => setLead(record)}
            >
              <i className="fa fa-comment m-r-5" /> Comments
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => edit(record)}>
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link className="dropdown-item" to={`/new-booking?leadId=${record.id}`}>
              <i className="fa fa-plus m-r-5" /> Add Booking
            </Link>
            <Link className="dropdown-item" to="#" onClick={(e) => doDelete(e, record)}>
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leads"
            title="Dashboard"
            subtitle="Leads"
            modal="#lead_form"
            name="Add Lead"
            canRefresh={true}
            canExport={true}
            showBtn={true}
            onRefresh={() => getLeads({page: 1, canExport: false})}
            doExport={() => {
              setExport(true);
              getLeads({page: 1, canExport: true});
            }}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={leads}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: data?.count,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      getLeads({page, canExport: false});
                    },
                  }}                
                />
              </div>
            </div>
          </div>
        </div>
        <AddLeadModal data={lead} onClose={() => setLead(null)} />

        <LeadCommentBox
          lead={lead} 
          closeComment={() => setLead(null)}
        />
      </div>
    </>
  );
};

export default Leads;
