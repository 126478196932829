import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { useGetGeneratorsQuery } from "../services/settings.service";
import { jobStatus } from "../utils";

const TicketFilter = ({onChange, filterData, doSearch, type}) => {
  const [generators, setGenerators] = useState([]);
  const [activeMonth, setActiveMonth] = useState(new Date());
  const {data: GenData } = useGetGeneratorsQuery();

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (GenData) {
      const gens = [{value: "", label: "Select All"}]
      GenData?.data.forEach((gen) => {
        gens.push({
          value: gen.id, 
          label: gen.name + ' (' + gen.size + ' kva)'
        })
      })
      setGenerators(gens)
    }
  }, [GenData]);

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (filterData.companyName === "") {
      setFocused(false);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div
            className={
              focused || filterData.companyName !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={filterData.companyName}
              onFocus={handleLabelClick}
              onBlur={handleInputBlur}
              onChange={(e) => onChange(e.target.value, 'companyName')}
            />
            <label className="focus-label" onClick={handleLabelClick}>
              Client Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="input-block form-focus select-focus">
            <Select
              options={jobStatus}
              placeholder="--Select--"
              styles={customStyles}
              onChange={({value}) => onChange(value, 'status')}
            />
            <label className="focus-label">Status</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="input-block form-focus select-focus">
            <Select
              options={generators}
              placeholder="--Select--"
              styles={customStyles}
              onChange={({value}) => onChange(value, 'generator')}
            />
            <label className="focus-label">Generator</label>
          </div>
        </div>
        {type !== 'confirmed' && 
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <div className="input-block form-focus select-focus">
            <div className="cal-icon">
              <DatePicker
                selected={dayjs(filterData.startDate).toDate()}
                onChange={(date) => onChange(date, 'startDate')}
                className="form-control floating datetimepicker"
                type="date"
                dateFormat="dd-MM-yyyy"
                // minDate={type === 'upcoming' ? dayjs().toDate() : ''}
              />
            </div>
            <label className="focus-label">From</label>
          </div>
        </div>}
        {type !== 'confirmed' ? 
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">
              <div className="cal-icon">
                <DatePicker
                  selected={dayjs(filterData.endDate).toDate()}
                  onChange={(date) => onChange(date, 'endDate')}
                  className="form-control floating datetimepicker"
                  type="date"
                  dateFormat="dd-MM-yyyy" // Add the placeholderText prop here
                  // maxDate={filterData.type === 'history' ? dayjs().toDate() : ''}
                />
              </div>
              <label className="focus-label">To</label>
            </div>
          </div>
          :
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">
              <div className="cal-icon">
                <DatePicker
                  selected={activeMonth}
                  onChange={(date) => {
                    onChange(date, 'month');
                    setActiveMonth(date);
                  }}
                  className="form-control floating datetimepicker"
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                />
              </div>
              <label className="focus-label">Month</label>
            </div>
          </div>
        }
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <Link 
          to="#" 
          onClick={doSearch} className="btn btn-success btn-block w-100">
            {" "}
            Search{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TicketFilter;
