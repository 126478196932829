import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { message, Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useGetBookingRequestsMutation, useUpdateBookingStatusMutation, useUpdateDeliveryStatusMutation } from "../../../services/booking.service";
import { exportToExcel, pad } from "../../../utils";
import dayjs from "dayjs";
import ViewGenerators from "../../../components/modelpopup/ViewGenerators";
import alertify from "alertifyjs";
import GenerateInvoiceModal from "../../../components/modelpopup/GenerateInvoiceModal";
import CommentBox from "../../../components/modelpopup/CommentBox";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_BOOKING } from "../../../services/constants";
import { useSelector } from "react-redux";


const BookingRequests = () => {
  const [generators, setGenerators] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [booking, setBooking] = useState(null);
  const [showFilter, toggleFilter] = useState(null);
  const [doExport, setExport] = useState(false);
  const [updateJobStatus, { isLoading: isJUpdating, isJSuccess, isJError }] = useUpdateBookingStatusMutation();
  const [updateDeliveryStatus, { isLoading: isDUpdating, isDSuccess, isDError }] = useUpdateDeliveryStatusMutation();
  const [getBooking, { data, isLoading: isLoading, isSuccess: isFetch, isError: isFetchError }] = useGetBookingRequestsMutation();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState({
    companyName: '',
    status: '',
    generator: '',
    startDate: dayjs().startOf('M').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('M').format('YYYY-MM-DD'),
    type: 'upcoming',
    page: 1,
  })

  const changeStatus = async (id, status, index) => {
    await updateJobStatus({id, status}).then(res => {
      const saveData = res.data
      if (saveData?.success ) {
        alertify.success("Saved successfully");
        const newArr = [...bookings];
        const newObj = {...newArr[index]}
        newObj.job_status = status;
        newArr[index] = newObj;
        setBookings(newArr);
      } else {
        alertify.error(saveData?.message);
      }
    })
  }

  const onChange = (value, field) => {
    setFilterData({
      ...filterData,
      [field]: value
    })
  }

  const init = useMemo(() => {
    getBooking(filterData)
  }, [])

  const changeDeliveryStatus = async (id, status, index) => {
    await updateDeliveryStatus({id, status}).then(res => {
      const saveData = res.data
      if (saveData?.success ) {
        alertify.success("Saved successfully");
        const newArr = [...bookings];
        const newObj = {...newArr[index]}
        newObj.delivery_status = status;
        newArr[index] = newObj;
        setBookings(newArr);
      } else {
        alertify.error(saveData?.message);
      }
    })
  }


  useEffect(() => {
    // const payload = {...filterData};
    // payload.startDate = dayjs(filterData.startDate).format('YYYY-MM-DD')
    // payload.endDate = dayjs(filterData.endDate).format('YYYY-MM-DD')
    getBooking(filterData);
  }, [])

  useEffect(() => {
    if (data && !doExport) { 
      setBookings(data.bookings.data);
    }

    if (data && doExport) {
      exportToExcel(data.bookings, `PPS Booking Requests ${filterData.startDate}-${filterData.endDate}`);
      setExport(false);
      setFilterData({...filterData, export: false})
    }
  }, [data]);

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Booking',
        url: `${DELETE_BOOKING}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <Link
              className="dropdown-item"
              to={`/booking/${r.id}`}
            >
              <i className="fa fa-eye m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#booking_comment_box"
              onClick={() => setBooking(r)}
            >
              <i className="fa fa-comment m-r-5" /> Add Comment
            </Link>
            <Link
              className="dropdown-item"
              to={`/new-booking?edit=${r.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            {r.invoiced ?
              <Link
                className="dropdown-item"
                to={`/invoice/${r.id}`}
              >
                <i className="fa fa-file-invoice m-r-5" /> View Invoice
              </Link>
            :
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#generate_invoice"
                onClick={() => setBooking(r)}
              >
                <i className="fa fa-file-invoice m-r-5" /> Generate Invoice
              </Link>
            }
            <Link
              className="dropdown-item"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Booking ID",
      dataIndex: "booking_id",
      render: (text, record) => (
        <Link
          onClick={() => localStorage.setItem("minheight", "true")}
          to={`/booking/${record.id}`}
        >
          #PPS-{pad(record.booking_id, 4)}
        </Link>
      ),
      sorter: (a, b) => a.booking_id.length - b.booking_id.length,
    },

    {
      title: "Client",
      dataIndex: "ticketsubject",
      sorter: (a, b) => a.ticketsubject.length - b.ticketsubject.length,
      render: (text, record) => (
        <span className="table-avatar">
         {record.customer.company_name}
        </span>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "priority",
      render: (t, r, idx) => (
        <div className="dropdown action-label">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
           {r.job_status === 'PAID' && <><i className="far fa-dot-circle text-success" /> Paid{" "}</>}
           {r.job_status === 'PENDING' && <><i className="far fa-dot-circle text-warning" /> Pending{" "}</>}
           {r.job_status === 'PARTIAL' && <><i className="far fa-dot-circle text-info" /> Partial{" "}</>}
           {r.job_status === 'CANCELLED' && <><i className="far fa-dot-circle text-danger" /> Cancelled{" "}</>}
           {r.job_status === 'NOT_PAID' && <><i className="far fa-dot-circle text-danger" /> Not Paid{" "}</>}
           {r.job_status === 'CLOSED' && <><i className="far fa-dot-circle text-success" /> Closed{" "}</>}
           {r.job_status === 'FREE' && <><i className="far fa-dot-circle text-info" /> Free{" "}</>}
           {r.job_status === 'REFUNDED' && <><i className="far fa-dot-circle text-danger" /> REFUNDED{" "}</>}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {r.job_status !== 'CANCELLED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'CANCELLED', idx)}>
                <i className="far fa-dot-circle text-danger" /> Cancelled
              </Link>
            }
            {r.job_status !== 'PENDING' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'PENDING', idx)}>
              <i className="far fa-dot-circle text-warning" /> Pending
              </Link>
            }
            {r.job_status !== 'PAID' &&
              <Link 
                className="dropdown-item" 
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#generate_invoice"
                onClick={() => changeStatus(r.id, 'PAID', idx)}
              >
              <i className="far fa-dot-circle text-success" /> Paid
              </Link>
            }
            {r.job_status !== 'PARTIAL' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'PARTIAL', idx)}>
              <i className="far fa-dot-circle text-info" /> Part Payment
              </Link>
            }
            {r.job_status !== 'NOT_PAID' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'NOT_PAID', idx)}>
              <i className="far fa-dot-circle text-danger" /> Not Paid
              </Link>
            }
            {r.job_status !== 'CLOSED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'CLOSED', idx)}>
              <i className="far fa-dot-circle text-success" /> Closed
              </Link>
            }
            {r.job_status !== 'FREE' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'FREE', idx)}>
              <i className="far fa-dot-circle text-info" /> Free
              </Link>
            }
            {r.job_status !== 'REFUNDED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeStatus(r.id, 'REFUNDED', idx)}>
              <i className="far fa-dot-circle text-danger" /> Refunded
              </Link>
            }
          </div>
        </div>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      sorter: (a, b) => a.event_date.length - b.event_date.length,
      render: (text, r) => (
        <div>
          {dayjs(r.startDate).format('DD MMM YYYY')}
          {" "}
          {r.startDate !== r.endDate && <>
          - {dayjs(r.endDate).format('DD MMM YYYY')}</>}
        </div>
      )
    },
    {
      title: "Generator",
      dataIndex: "name",
      render: (text, record) => (
        record.generators.length > 0 ?
        <div>
          {record.generators.length > 1 ?
            <Link
              className="text-info"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#generator_details"
              onClick={() => setGenerators(record?.generators)}
            >
              {record.generators[0].generator.name} ({record.generators[0].generator.size}) + {record.generators.length - 1} more
            </Link>
          :
            `${record.generators[0]?.generator?.name} (${record.generators[0]?.generator?.size}kva)`
          }
        </div>
        : 'N/A'
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      // render: () => (<div>Soul Beach, Oniru</div>)
    },
    {
      title: "Contact Details",
      dataIndex: "priority",
      render: (text, record) => (
        <div>
          {record.customer.contact_name && <div><span className="text-bold">Name:</span> {record.customer.contact_name}</div>}
          {record.customer.phone_no && <div><span className="text-bold">Phone No:</span> {record.customer.phone_no}</div>}
        </div>
      ),
    },
    {
      title: 'Extras',
      render: (t, record) => (
        <div>
          {record.extra_cable && <div><span className="text-bold">XCable: </span>{record.extra_cable}m</div>}
          {record.distribution_box && <div><span className="text-bold">DB: </span>{record.distribution_box} DBs</div>}
          {record.change_over && <div><span className="text-bold">C/O: </span>{record.change_over} units</div>}
          {record.supply_diesel && <div><span className="text-bold">Diesel:</span> {record.supply_diesel}L</div>}
        </div>
      )
    },
    {
      title: "Technician",
      dataIndex: "priority",
      // render: () => (<div></div>)
    },
    {
      title: "Delivery Status",
      dataIndex: "status",
      render: (t, r, index) => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            aria-expanded="false"
            data-bs-toggle="dropdown"
          >
            {r.delivery_status === 'DELIVERED' && <><i className="far fa-dot-circle text-success" /> Delivered{" "}</>}
            {r.delivery_status === 'NOT_STARTED' && <><i className="far fa-dot-circle text-warning" /> Pending{" "}</>}
            {r.delivery_status === 'IN_TRANSIT' && <><i className="far fa-dot-circle text-info" /> In-Transit{" "}</>}
            {r.delivery_status === 'CANCELLED' && <><i className="far fa-dot-circle text-danger" /> Cancelled{" "}</>}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {r.delivery_status !== 'CANCELLED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeDeliveryStatus(r.id, 'CANCELLED', index)}>
                <i className="far fa-dot-circle text-danger" /> Cancelled
              </Link>
            }
            {r.delivery_status !== 'NOT_STARTED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeDeliveryStatus(r.id, 'NOT_STARTED', index)}>
              <i className="far fa-dot-circle text-warning" /> Pending
              </Link>
            }
            {r.delivery_status !== 'DELIVERED' &&
              <Link className="dropdown-item" to="#" onClick={() => changeDeliveryStatus(r.id, 'DELIVERED', index)}>
              <i className="far fa-dot-circle text-success" /> Delivered
              </Link>
            }
            {r.delivery_status !== 'IN_TRANSIT' &&
              <Link className="dropdown-item" to="#" onClick={() => changeDeliveryStatus(r.id, 'IN_TRANSIT', index)}>
              <i className="far fa-dot-circle text-info" /> In-Transit
              </Link>
            }
          </div>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Date Booked",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      render: (text) => (dayjs(text).format('DD MMM YYYY HH:mm'))
    },
    {
      title: "Action",
      render: (t, r) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={`/booking/${r.id}`}
            >
              <i className="fa fa-eye m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to={`/new-booking?edit=${r.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#booking_comment_box"
              onClick={() => setBooking(r)}
            >
              <i className="fa fa-comment m-r-5" /> Add Comment
            </Link>
            {r.invoiced ?
              <Link
                className="dropdown-item"
                to={`/invoice/${r.id}`}
              >
                <i className="fa fa-file-invoice m-r-5" /> View Invoice
              </Link>
            :
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#generate_invoice"
                onClick={() => setBooking(r)}
              >
                <i className="fa fa-file-invoice m-r-5" /> Generate Invoice
              </Link>
            }
            <Link
              className="dropdown-item"
              onClick={(e) => doDelete(e, r)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      sorter: false,
    },
  ];


  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Booking Requests"
            title="Dashboard"
            subtitle="Bookings"
            modal="#"
            name="Add Booking"
            link="/new-booking"
            showBtn={true}
            canRefresh={true}
            canExport={true}
            canFilter={true}
            onRefresh={() => getBooking(filterData)}
            toggleFilter={() => toggleFilter(!showFilter)}
            doExport={() => {
              setExport(true);
              getBooking({...filterData, export: true});
            }}
          /> 

        {showFilter &&
          <TicketFilter onChange={onChange} filterData={filterData} doSearch={() => getBooking(filterData)} type="upcoming" />
        }
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={bookings?.length > 0 ? bookings : []}
                  loading={isLoading}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: data?.totalBookings,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      const payload = {...filterData}
                      setFilterData({...filterData, page})
                      payload.page = page;
                      payload.startDate = dayjs(filterData.startDate).format('YYYY-MM-DD')
                      payload.endDate = dayjs(filterData.endDate).format('YYYY-MM-DD')
                      getBooking(payload);
                    },
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewGenerators 
        generators={generators} 
        closeGen={() => setGenerators([])}
      />
      <CommentBox 
        booking={booking} 
        closeComment={() => setBooking(null)}
      />
      <GenerateInvoiceModal 
        booking={booking}
        closeGen={() => setBooking(null)}
      />

    </>
  );
};

export default BookingRequests;
