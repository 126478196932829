import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import {
  Avatar_06,
} from "../../../Routes/ImagePath";
import { ClientModelPopup } from "../../../components/modelpopup/ClientModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ClientsFilter from "../../../components/ClientsFilter";
import { useLazyGetCustomersQuery } from "../../../services/customer.service";
import { exportToExcel, pad } from "../../../utils";
import { useDispatch } from "react-redux";
import { CUSTOMERS } from "../../../services/constants";
import { toggleDelete } from "../../../redux/slices/ui.slice";

const ClientList = () => {

  const [getCustomers, { data, isFetching, error }] = useLazyGetCustomersQuery()
  const [doExport, setExport] = useState(false);
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getCustomers({search: '', page: 1, canExport: false})
  }, [])

  useEffect(() => {
    getCustomers({search, page: 1, canExport: false})
  }, [search])

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Client Info',
        url: `${CUSTOMERS}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const edit = item => {
    setCustomer(item);
    document.getElementById('add-btn').click();
  }

  useEffect(() => {
    if (data && !doExport) { 
      setCustomers(data.data.data);
    }

    if (data && doExport) {
      exportToExcel(data.data, `PPS Customers List`);
      setExport(false)
    }
  }, [data]);

  const columns = [
    {
      title: "",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={`/customer/${record.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => edit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to={`/customer/${record.id}`} className="avatar">
            <img alt="" src={Avatar_06} />
          </Link>
          <Link to={`/customer/${record.id}`}>{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: "Client ID",
      dataIndex: "customer_id",
      sorter: (a, b) => a.customer_id - b.customer_id,
      render: (t, r) => (`CLT-${pad(r.customer_id, 4)}`)
    },

    {
      title: "Contact Person",
      dataIndex: "contact_name",
      sorter: (a, b) => a.contact_name.length - b.contact_name.length,
      render: (t, r) => (
        <div class="contact-info">
          <span class="contact-name text-ellipsis">{r.contact_name}</span>
          <span class="contact-date">{r.contact_phone}</span>
        </div>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Mobile",
      dataIndex: "phone_no",
      sorter: (a, b) => a.Mobile.length - b.Mobile.length,
    },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   render: (text) => (
    //     <div className="dropdown">
    //       <Link
    //         to="#"
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i
    //           className={
    //             text === "Active"
    //               ? "far fa-dot-circle text-success"
    //               : "far fa-dot-circle text-danger"
    //           }
    //         />{" "}
    //         {text}{" "}
    //       </Link>
    //       <div className="dropdown-menu">
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-success" /> Active
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-danger" /> Inactive
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={`/customer/${record.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => edit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Clients"
            title="Dashboard"
            subtitle="Clients"
            modal="#add_client"
            name="Add Client"
            Linkname="/clients"
            Linkname1="/clients-list"
            canRefresh={true}
            canExport={true}
            onRefresh={() => getCustomers({search: '', page: 1, canExport: false})}
            doExport={() => {
              setExport(true);
              getCustomers({search: '', page: 1, canExport: true})
            }}
          />
          {/* /Page Header */}
          <ClientsFilter 
            doSearch={(val) => {
              setSearch(val);
              getCustomers({search: val, page: 1, canExport: false})
            }} 
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={customers}
                  rowKey={(record) => record.id}
                  loading={isFetching}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: data?.data?.count,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      getCustomers({search, page, canExport: false})
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientModelPopup data={customer} onClose={() => setCustomer(null)} />
    </>
  );
};

export default ClientList;
